<template>
  <footer class="wrap">
    <!-- <div>
      <img class="logo" src="./../assets/logo.png" alt>
    </div> -->
    <!-- <div class="main">
      <div>
        <h2 class="left">Discord</h2>
      </div>
      <div class="middle-col">
        <h2>twitter</h2>
        <!-- <Timeline v-if="!prerenderHideElement" :id="'maybelatergames'" :sourceType="'profile'" :options="{ tweetLimit: '1', theme: 'dark' }"/> -->
        <!-- <div>
          <div>twitter</div>
          <div>facebook</div>
        </div>
      </div>
      <div>
        <h2 class="right">games</h2>
        <img src="../assets/footer-aa.png" alt="">
      </div>
    </div> -->
    <div class="signup">
      <div class="rect">test</div>
      <h2 class="long-title">Maybe Later Games Newsletter</h2>
      <div class="center">
        <img class="lines" src="../assets/signup-lines.svg" alt="">
        <div class="block">
          <p>Sign up to our newsletter and keep up to date with all our new game releases!</p>
          <div class="form">
            <input type="text" placeholder="Enter email" v-model="email">
            <button :disabled="!isEmailValid" :class="{ 'active': isEmailValid }" @click="signUpToNewsletter(email)">
              Subscribe
            </button>
          </div>
          <img class="mailchimp" src="../assets/mailchimp.png" alt="">
        </div>
        <img class="lines" src="../assets/signup-lines.svg" alt="">

      </div>
    </div>
    <div class="subnav">
      <div class="inner">
        <a href="">Contact</a>
      </div>
    </div>
  </footer>
</template>

<script>
// import { Timeline } from 'vue-tweet-embed';
import isEmail from 'validator/lib/isEmail';
import Subscribe from '../mixins/emailSubscribe';

export default {
  name: 'footer-section',
  // components: { Timeline },
  mixins: [ Subscribe ],
  data() {
    return {
      email: '',
      prerenderHideElement: false, // this is to hide show prerendered blocks
    };
  },
  computed: {
    isEmailValid() {
      return isEmail(this.email);
    },
  },
  mounted(){
    // Prerender function
    if (typeof window.__PRERENDER_INJECTED !== "undefined") {
      this.prerenderHideElement = window.__PRERENDER_INJECTED.prerenderHide
    }
    setTimeout(() => {
      this.ready = true;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

// @include for-tablet-portrait-up {
//   font-size: 27px;
// }

.wrap {
  background: #1f070e;
  padding: 30px 0 0;
  text-align: center;
  font-family: "houschka-rounded";
}

.main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1100px;
  margin: auto;
}

h2 {
  color: #d3093b;
  font-family: "houschka-rounded";
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.1em;
  display: inline-block;
  position: relative;
  margin: 0 auto 28px;
  &::after {
    content: '';
    width: 110%;
    height: 2px;
    border-radius: 10px;
    background: rgb(211, 9, 59);
    bottom: -7px;
    position: absolute;
    left: -5%;
  }
  &.left {
    &::after {
      transform: rotate(2deg)
    }
  }
  &.right {
    &::after {
      transform: rotate(-2deg)
    }
  }
  // .long-title {
  //   &::after {
  //     content: '';
  //     width: 110%;
  //     height: 2px;
  //     border-radius: 10px;
  //     background: rgb(211, 9, 59);
  //     bottom: -8px;
  //     position: absolute;
  //     left: -5%;
  //     transform: rotate(0deg)
  //   }
  // }
}



.logo {
  max-width: 120px;
  margin: auto;
  // background: rgb(189, 15, 58);
}

.middle-col {
  margin-top: 40px;
}

.signup {
  background: #360815;
  padding: 80px 0 60px;
  border-top: 4px solid #d3093b;
  position: relative;
}

.rect {
  background-image: url('../assets/signup-rect.svg');
  background-repeat: repeat-x;
  background-position-x: center;
  background-size: 60px;
  height: 60px;
  width: 100%;
  position: absolute;
  top: -26px;
}

.center {
  text-align: center;
  display: grid;
  grid-template-columns: auto minmax(240px, 2fr) auto;
  grid-gap: 30px;
  padding: 0 20px;
  @include for-tablet-portrait-up {
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 30px;
  }

  grid-gap: 30px;
  align-items: center;
  justify-items: center;
  margin-top: -10px;

  .lines {
    width: 30px;
  }

  .block {
    max-width: 460px;
    width: 100%;
    input {
      padding: 10px 18px;
      border-radius: 6px;
      border: 0px;
      // border: 2px solid #df063c;

      font-size: 18px;
      background: #d3093b;
      // background: #290610;
      color: rgb(255, 240, 240);
      font-weight: 600;
      &::placeholder {
        color: #1f070e;
      }
      // width: 100%;
      display: block;
      // max-width: 280px;
    }
    p {
      color: #ffdbe4;
      font-size: 16px;
      line-height: 1.4em;
      margin: 26px auto;
      max-width: 380px;
    }
    button {
      background: #360815;
      color: #df063c;
      border: 2px solid #df063c;
      padding: 10px 18px;
      // margin-left: 10px;
      border-radius: 6px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      opacity: 0.4;
      letter-spacing: 0.05em;
      cursor: pointer;
      transition: 0.15s ease-in-out;
      &.active {
        opacity: 1;
        background: #df063c;
        color: #fff;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .form {
      display: grid;
      // grid-template-columns : 1fr 1fr;
      @include for-tablet-portrait-up {
        grid-template-columns : 1fr auto;
      }

      grid-gap: 14px 20px;
      // justify-content: center;
    }
    .mailchimp {
      width: 70px;
      opacity: 0.5;
      margin: 16px auto 0;
    }
  }
}

.subnav {
  background: #000;
  padding:10px 0 14px;
  .inner {
    a {
      color: #910024;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
</style>
