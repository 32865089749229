<template>
  <div class="nav">
    <div class="nav-wrap">
      <div class="logo">
        <img src="./../assets/logo.png" alt="">
      </div>
      <ul>
        <li>
          <router-link :to="{name: 'home'}">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'sls-home'}">SpireSpy</router-link>
        </li>
        <li>
          <a href="https://store.steampowered.com/app/1152580/Ann_Achronist_Many_Happy_Returns/">Ann Achronist</a>
        </li>
        <li>
          <a href="https://store.steampowered.com/app/1174890/">No Offence, But<span class="new">new</span></a>
        </li>
        <li>
          <router-link :to="{name: 'blog'}">Blog</router-link>
        </li>
        <!-- <li>
          <a href="">About</a>
        </li>
        <li>
          <a href="">Games</a>
        </li>
        <li>
          <a href="">Tools</a>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.nav {
  // text-align: center;
  font-family: "houschka-rounded";
  padding: 10px;
  &-wrap {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


  }
  .logo {
    max-width: 120px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 660px) {
      flex-direction: row;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    position: relative;
    display: block;

    @media (min-width: 660px) {
      flex-direction: row;
      padding: 20px;

      &:hover {
        background: rgba(rgb(11, 9, 121), 0.4)
      }
    }
  }
  .new {
    background: rgb(207, 33, 33);
    border-radius: 7px;
    position: absolute;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1px 5px 0;
    top: 50%;
    right: 0%;
    transform: translate(120%, -50%);

    @media (min-width: 660px) {
      top: 4%;
      right: 50%;
      transform: translateX(50%);

    }
  }
}
</style>
