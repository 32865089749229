<template>
<div class="body">
  <navbar></navbar>
  <router-view/>
  <footerblock></footerblock>

</div>
  
</template>

<script>
import navbar from './nav';
import footerblock from './footer';

export default {
  name: 'Homepage',
  components: { navbar, footerblock },
  data() {
    return {

    };
  },
};
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import url('https://use.typekit.net/kpj2pou.css');
@import url('https://fonts.googleapis.com/css?family=Jomhuria&display=swap');

.body {
  background: hsl(209, 50%, 8%);
  // padding: 0 30px 50px;
  @media (min-width: 800px) {
    // padding: 0 0 0;
  }
  // padding: 0px;
  // border: 1px solid transparent;
  // min-height: 100vh;
}

h1, h2, p {
  font-weight: normal;
  // text-align: center;
  font-family: 'houschka-rounded';
  font-display: swap;
}

h1, h2 {
  font-weight: bold;
}

h1 {
  font-size: 24px;
}

.h1 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  margin-top: 80px;
}

ul {
  list-style: none;
}

a {
  // color: #42b983;
  text-decoration: none;
}
.hello {
  max-width: 1200px;
  margin: auto;
}

.box-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > li {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    overflow: hidden;
    margin: 20px;
    transition: 0.25s ease-in-out;
    background: #fff;

    @media (min-width: 660px) {
      width: 40%;
      max-width: 360px;
    }

    &:hover {
      transform: scale(1.05);
      .text {
        transform: translateY(-20px);
      }
    }
    // .img-curtain {
    //   background: #fff;
    //   // transform: scale(1.05);
    // }
    .text {
      padding: 14px 22px;
      background: #fff;
      transition: 0.25s ease-in-out;

      h1 {
        margin: 4px 0 8px;
      }
      p {
        color: #888;
        margin: 4px 0 6px;
      }
      .title-sls {
        color: rgb(211, 47, 18);
      }
      .title-jjm {
        color: #583A6F;
      }
    }
    
  }
}

.main-grid {
  display: grid;
  grid-template-columns: 1;
  .height {
    min-height: 90vh;
    background-image: url("./../assets/backgroundSky.png");
    background-size: cover;
    position: relative;
    overflow: hidden;
    @media (min-width: 800px) {
      min-height: 100vh;
    }
    .logo {
      display: block;
      position: absolute;
      width: 90%;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      @media (min-width: 800px) {
        left: 50%;
        top: 8%;
        width: 44%;
      }
      // transform: translateX(-50%);
    }
  }
  
  .juggle1 {
    position: absolute;
    top: 0%;
    left: 16%;
    width: 32%;
  }

  .juggle2 {
    position: absolute;
    top: 19%;
    left: 28%;
    width: 55%;
  }

  .madness {
    position: absolute;
    width: 75%;
    bottom: 14%;
    right: 5%;
  }
  
  .ball1 {
    position: absolute;
    width: 21%;
    top: -9%;
    left: 43%;
  }
  
  .ball2 {
    position: absolute;
    width: 20%;
    bottom: 6%;
    right: 10%;
  }

  .bomb {
    position: absolute;
    width: 21%;
    top: 20%;
    left: 5%;
  }
  
  
  .big-tent {
    position: absolute;
    bottom: -5%;
    transform: scale(1.05);
    // width: 100%;
    // width: 1500px;
  }
  .small-tent {
    position: absolute;
    bottom: -3%;
    width: 25%;
  }
  .small-tent1 {
    width: 15%;
    bottom: -3%;
  }
  .flags {
    position: absolute;
    top: 0;
    width: 25%;
  }
  .flags1 {
    transform: scaleX(-1);
    
  }

  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  .button-wrap {
    display: block;
    position: absolute;
    left: 0;
    bottom: 16%;
    width: 100%;
    text-align: center;
    .play {
      /* Rectangle 7: */
      box-shadow: 0 14px 0 -4px rgba(27,2,43,0.50), inset 12px -12px 0 0 #CF3C56;
      background: #EA455E;
      border-radius: 100px;
      border: none;
      height: 100px;
      // width: 20%;
      transform: scale(1,1);
      color: #fff;
      font-size: 68px;
      font-family: 'Jomhuria', cursive;
      padding: 10px 50px 0 50px;
      transition: 0.5s;
      outline: none;
      &:hover {
        transform: scale(1.1,1.1);
        box-shadow: 0 44px 0 -20px rgba(27,2,43,0.50), inset 6px -6px 0 0 rgb(212, 62, 90);
        background: rgb(247, 68, 95);

      }
    }
  }
}


.footer-title {
  color: #fff;
  text-align: center;
  padding: 50px;
  background: rgb(15, 15, 15);
}

@-webkit-keyframes wobble  {
  0%  { transform:  rotate(0deg) scale(0.98, 1.08); }
  20%  { transform:  rotate(-2deg) scale(1.02, 0.96); }
  40%  { transform:  rotate(1deg) scale(1.02, 1.02); }
  60%  { transform:  rotate(3deg) scale(0.98, 0.98); }
  80%  { transform:  rotate(1deg) scale(0.95, 1.04); }
  100%  { transform:  rotate(0deg) scale(0.98,1.08); }
}
.wobble { animation: wobble 12s infinite ease-in-out; }

@-webkit-keyframes balls  {
  0%  { transform:  rotate(0deg) translate(0,0)}
  20%  { transform:  rotate(-1deg) translate(-3%,4%)}
  35%  { transform:  rotate(3deg) translate(3%,0)}
  50%  { transform:  rotate(-1deg) translate(0, 3%)}
  65%  { transform:  rotate(3deg) translate(-5%, -3%)}
  80%  { transform:  rotate(1deg) translate(-2%,1%)}
  100%  { transform:  rotate(0deg) translate(0,0)}
}

.zoom-ball1 { animation: balls 14s infinite ease-in-out; }
.zoom-ball2 { animation: balls 15s infinite ease-in-out; }
.zoom-bomb { animation: balls 16s infinite ease-in-out; }


// @-webkit-keyframes zoom-juggle1  {
//   0%  { transform:  rotate(0deg) scale(0.98, 1.08); }
//   20%  { transform:  rotate(-2deg) scale(1.02, 0.96); }
//   40%  { transform:  rotate(1deg) scale(1.02, 1.02); }
//   60%  { transform:  rotate(3deg) scale(0.98, 0.98); }
//   80%  { transform:  rotate(1deg) scale(0.95, 1.04); }
// }

// @-webkit-keyframes zoom-juggle1  {
//   0%  { transform:  rotate(0deg) scale(0.98, 1.08) rotate3d(0, 0 , 1, 0deg); }
//   80%  { transform:  rotate(0deg) scale(0.98, 1.08) rotate3d(0, 0 , 1, 0deg); }
//   85%  { transform:  rotate(-2deg) scale(1.02, 0.96) rotate3d(0, 0 , 1, 360deg); }
//   40%  { transform:  rotate(1deg) scale(1.02, 1.02) rotate3d(0, 0 , 1, 0deg); }
//   60%  { transform:  rotate(3deg) scale(0.98, 0.98) rotate3d(0, 0 , 1, 0deg); }
//   80%  { transform:  rotate(1deg) scale(0.95, 1.04) rotate3d(0, 0 , 1, 0deg); }
// }


@-webkit-keyframes zoom-juggle1  {
  0%  { transform: scale(1, 1); }
  70%  { transform: scale(1, 1); }
  74%  { transform:  scale(1.2, 1.2); }
  78%  { transform: scale(0.95, 0.95); }
  82%  { transform: scale(1, 1); }
  100%  { transform: scale(1, 1); }
}
@-webkit-keyframes zoom-juggle2  {
  0%  { transform: scale(1, 1); }
  75%  { transform: scale(1, 1); }
  79%  { transform:  scale(1.2, 1.2); }
  83%  { transform: scale(0.95, 0.95); }
  87%  { transform: scale(1, 1); }
  100%  { transform: scale(1, 1); }
}
@-webkit-keyframes zoom-madness  {
  0%  { transform: scale(1, 1); }
  81%  { transform: scale(1, 1); }
  85%  { transform:  scale(1.2, 1.2); }
  89%  { transform: scale(0.95, 0.95); }
  93%  { transform: scale(1, 1); }
  100%  { transform: scale(1, 1); }
}

.zoom-juggle1 { animation: zoom-juggle1  20s infinite ease-in-out; }
.zoom-juggle2 { animation: zoom-juggle2  20s infinite ease-in-out; }
.zoom-madness { animation: zoom-madness  20s infinite ease-in-out; }

</style>
