<script>
import gql from 'graphql-tag';

export default {
  methods: {
    async signUpToNewsletter(emailAddress) {
      const signupEmailAddress = emailAddress;
      // Call to the graphql mutation
      try {
        const result = await this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($emailAddress: String!) {
            createNewsletterSubscriber(emailAddress: $emailAddress){
              success
              message
            }
          }`,
          // Parameters
          variables: {
            emailAddress: this.email,
          },
        });
      } catch (error) {
        // @tslint:disable-next-line:no-console
        console.log(error);
        this.errorSignupMessageSlack()
      }
    },

    errorSignupMessageSlack() {
      const vue = this;
      const message = {
        attachments: [
            {
                fallback: 'New email subscriber with server error',
                color: '#FF002E',
                title: 'Server error for new subscriber - add to list',
                text: this.email,
            },
        ],
      };
      fetch('https://hooks.slack.com/services/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', {
        method: 'post',
        body: JSON.stringify(message),
        })
        .then(() => {
          vue.name = '';
          vue.message = '';
          vue.messageSuccessAni();
        })
        .catch((error) => {
          // @tslint:disable-next-line:no-console
          console.log(error);
        });
    },
  },
};
</script>
